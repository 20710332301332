/** @format */

@import "../../assets/styles/theme";

.dashboard_calendar {
  padding: 2rem;

  .box {
    border-radius: 1rem;
    width: 100%;
    height: 10rem;
    background-color: $card;
  }

  .dashboard_calendar .ant-picker-cell{
    padding: 0.1rem;
  }

  .dashboard_calendar .ant-picker-cell-inner{
    // background-color: rgb(201, 201, 201);
    border-radius: 0.3rem;
    padding: .3rem .5rem;
    height: auto;
    width: max-content;
  }
}

.statistics-table-item-border{
  border-bottom: 1px solid #dddddd;
}

.statistics-table-item-border:last-child{
  border: none;
}