/** @format */

@import "./variable.scss";


// --------------------- default variable ---------------------------------
:root {
  --v-border: var(--light-border);
  --v-text: var(--light-text);
  --v-h_text: var(--light-h_text);
  --v-active_text: var(--light-active_text);
  --v-placeholder: var(--light-placeholder);
  --v-blue: var(--light-blue);
  --v-light_blue: var(--light-light_blue);
  --v-shadow_blue: var(--light-shadow_blue);
  --v-bg: var(--light-bg);
  --v-card: var(--light-card);
  --v-element: var(--light-element);
}


//-------------------------- change theme ----------------------------
[data-theme="dark"] {
  --v-border: var(--dark-border);
  --v-text: var(--dark-text);
  --v-h_text: var(--dark-h_text);
  --v-active_text: var(--dark-active_text);
  --v-placeholder: var(--dark-placeholder);
  --v-blue: var(--dark-blue);
  --v-light_blue: var(--dark-light_blue);
  --v-shadow_blue: var(--dark-shadow_blue);
  --v-bg: var(--dark-bg);
  --v-card: var(--dark-card);
  --v-element: var(--dark-element);
}

[data-theme="light"] {
  --v-border: var(--light-border);
  --v-text: var(--light-text);
  --v-h_text: var(--light-h_text);
  --v-active_text: var(--light-active_text);
  --v-placeholder: var(--light-placeholder);
  --v-blue: var(--light-blue);
  --v-light_blue: var(--light-light_blue);
  --v-shadow_blue: var(--light-shadow_blue);
  --v-bg: var(--light-bg);
  --v-card: var(--light-card);
  --v-element: var(--light-element);
}

[data-theme="flat"] {
  --v-border: var(--flat-border);
  --v-text: var(--flat-text);
  --v-h_text: var(--flat-h_text);
  --v-active_text: var(--flat-active_text);
  --v-placeholder: var(--flat-placeholder);
  --v-blue: var(--flat-blue);
  --v-light_blue: var(--flat-light_blue);
  --v-shadow_blue: var(--flat-shadow_blue);
  --v-bg: var(--flat-bg);
  --v-card: var(--flat-card);
  --v-element: var(--flat-element);
}

[data-theme="blue"] {
  --v-border: var(--blue-border);
  --v-text: var(--blue-text);
  --v-h_text: var(--blue-h_text);
  --v-active_text: var(--blue-active_text);
  --v-placeholder: var(--blue-placeholder);
  --v-blue: var(--blue-blue);
  --v-light_blue: var(--blue-light_blue);
  --v-shadow_blue: var(--blue-shadow_blue);
  --v-bg: var(--blue-bg);
  --v-card: var(--blue-card);
  --v-element: var(--blue-element);
}


// -------------------- sass variable -----------------------------
$border: var(--v-border);
$text: var(--v-text);
$h_text: var(--v-h_text);
$active_text: var(--v-active_text);
$placeholder: var(--v-placeholder);
$blue: var(--v-blue);
$light_blue: var(--v-light_blue);
$shadow_blue: var(--v-shadow_blue);
$bg: var(--v-bg);
$card: var(--v-card);
$element: var(--v-element);

























// // ---------default theme (flat)--------------------------------------

//   $border: #3c444e;
//   $text: #768390;
//   $h_text: #adbac7;
//   $active_text: #abb2bf;
//   $placeholder: #454b59;
//   // $blue: #1d5ffd;
//   $blue: #116fe9;
//   $light_blue: #1a73e8;
//   $shadow_blue: #1e3969;

//   // $bg: #1B2227;
//   // $bg: #1e252b;
//   $bg: #171b1f;
//   // $card: #20262c;
//   $card: #22272E;
//   // $element: #2e363d;
//   $element: #2D333B;
//   // $element: #263240

// // -----------dark theme----------------------------------
// [data-theme = dark] {
//   $border: #454a50;
//   $text: #898e94;
//   $h_text: #b8c0c7;
//   $active_text: #b2b6be;
//   $placeholder: #575a5f;
//   $blue: #2276e4;
//   $light_blue: #2276e4;
//   $shadow_blue: #26406d;

//   // $bg: #1B2227;
//   $bg: #16181a;
//   $card: #24272b;
//   $element: #2e3238;
//   // $element: #263240
// }

// // -----------light theme----------------------------------
// [data-theme = light]{
//   $border: #3c444e;
//   $text: #4e555e;
//   $h_text: #363a3d;
//   $active_text: #2c2e31;
//   $placeholder: #757a85;
//   $blue: #3c77ff;
//   $light_blue: #3682e6;
//   $shadow_blue: #bfd6fd;

//   $bg: #ffffff;
//   $card: #e8eaee;
//   $element: #d3d8df;
// }
