
.not-data{
  margin-top: 10rem;
  text-align: center;
  img{
    width: 160px;
  }
  h1{
    font-size: 3rem !important;
    margin: 2rem 0 !important;
  }

}