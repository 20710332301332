/** @format */

@import "../../../assets/styles/theme";
@import "../../../assets/styles/sidebar";

.layout {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  // background-color: $bg;
  // color: $text;
  overflow: hidden;
  // text-align: center;


  .content {
    position: relative;
    width: calc(100vw - $sidebar);
    height: calc(100vh - var(--header));
    overflow-y: scroll;
    margin-top: var(--header);
    margin-left: $sidebar;
    transition: var(--animation-duration) linear;

    top: 0;
    left: 0;
    bottom: 0;

    .blur-effect-centent::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: calc(100vh - var(--header));
      background-color: rgba(255, 255, 255, 0.5); /* 10% #ffffff */
      backdrop-filter: blur(10px); /* Faqat background blur */
      z-index: 0; /* Pastki qatlamda bo'lishi uchun */
    }
    
    .blur-effect-centent {
      position: relative;
      bottom: 0;
      top: 0;
      z-index: 1; /* Kontentni background ustiga qo'yish */
    }
  }

  h1 {
    font-size: 5rem;
    margin: 3rem auto;
    // color: #2089d9;
  }
}

.no-layout {
  position: absolute;
  top: 0;
  // bottom: 0;
  width: 100%;
  min-height: 100vh;
  background-color: $bg;
  color: $text;
  // overflow: hidden;
  text-align: center;

  *::selection {

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

  }

  .content {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    transition: 0.15s linear;
  }

  h1 {
    font-size: 5rem;
    margin: 3rem auto;
    color: #2089d9;
  }
}

.extra-layout {
  .ant-menu,
  .ant-menu-sub,
  .ant-menu-inline{
    border-inline-end: none !important;
  }
  .ant-menu-item {
    height: 38px;
  }
}
