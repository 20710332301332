
.add-content-btn-wrapper{
  padding: .25rem 0;

  .divider{
    // display: none;
    // opacity: 0;
    transition: var(--animation-duration);
  }



  .add-content-btn{
    cursor: pointer;
    transition: var(--animation-duration);

    .type-btn-wrapp{
      width: 0;
      overflow: hidden;
      transition: var(--animation-duration);

      &:hover{
        width: 416px;
      }
    }

    &:focus{
      .type-btn-wrapp{
        transition: var(--animation-duration);
        width: 416px;
      }
    }
  }

  &:hover{
    .divider{
      display: flex;
      opacity: 1;
    }
    // .type-btn-wrapp{
    //   transition: var(--animation-duration);
    //   width: 416px;
    // }
  }
}

.content-card{
  img {
    max-width: 100%;
  }
  .ant-upload{
    width: 100%;
  }

  .sun-editor{
    border: none;
    border-radius: 0 0 .5rem .5rem;

    .se-resizing-bar{
      border-radius: 0 0 .5rem .5rem;

    }
    .se-toolbar{
      outline: none;
      // background-color: white;
    }

    .se-btn-module-border{
      border: none;
    }

    .se-btn{
      border-radius: .5rem;
    }

  }
}

.file_viewer{
  display: inline-block;
  // display: block;
  min-width: 50%;
  max-width: 90% ;
  margin: 0 auto;
  border-radius: .5rem;
}

.file-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 400px;
  max-width: 500px;
  padding: 1rem 2rem;
  margin: 1rem auto;
  background-color: #E6F7FF;
  border-radius: 1rem;

  .file-name{
    margin-left: 1rem;
    font-weight: 500;
  }

  .download{
    width: 2rem ;
    height: 2rem;
    border-radius: 50%;
    background-color: #88d9ff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}